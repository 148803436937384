import { $authHost } from './api'
import { AxiosResponse } from 'axios'
import { IUser } from '@/types/users'

interface IDelPayload {
  userId: number
  token: string
}
interface ISearchPayload {
  email: string
  token: string
}

class UsersService {
  async search({email, token}: ISearchPayload): Promise<IUser[]> {
    const { data }: AxiosResponse<IUser[]> = await $authHost.post('/support/search-users', {
      email
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      }
    })
    return data
  }

  async del({userId, token}: IDelPayload): Promise<boolean> {
    const { data }: AxiosResponse<boolean> = await $authHost.post('/support/untie-email-user', {
      user_id: userId
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      }
    })
    return data
  }
}

export default new UsersService()
